import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeBannerImage from '../assets/images/home/landing-row-bg.png';
import { Link } from 'gatsby';

export default function LivePage(props) {
    return <Layout lang="ar">
        <SEO title="Live" />
        <main style={{ paddingTop: '100px' }}>
            <div
                className="w-100"
                style={{
                    height: '100vh',
                    backgroundImage: `url(${HomeBannerImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            >
                <div className="h-100 w-100 flex items-center justify-center">
                    <div className="uk-position-left uk-position-center uk-panel ph5 hero-text-container">
                        <span className="font-nostromo f3 f1-ns" style={{ fontWeight: '600', lineHeight: '1.2em', color: '#fff' }}>
                            <div style={{ minWidth: '200px', maxWidth: '400px' }}>
                        تابعوا البث المباشر لوصول مسبار الأمل إلى المريخ
                            </div>
                        </span>
                        <div className="heading-underline-light mt4"></div>
                        <h2 className="font-martian white f4 f3-s" style={{ fontWeight: '300' }}>قريبًا</h2>
                    </div>
                    <div className="absolute ml4 mb4-ns mt0-ns mt7 pt6 pt0-ns bottom-0-ns left-0">
                        <Link to="/ar/gallery/emm-launch-videos">
                            <button className="uk-button uk-button-default pointer" style={{ background: 'rgba(0,0,0,0.5)', color: '#fff' }}>
                                <span uk-icon="icon: triangle-right; ratio: 2" style={{ marginLeft: '-20px' }}></span> <span className="f6 b">تابعوا فيديوهات الإطلاق</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
}